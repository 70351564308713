
.top{
    display: flex;
    align-items: center;    padding-top: 220px;
    flex-direction: column;
    padding-bottom: 60px;
 color: white !important;
 /*
 background-image: url(img/bg-medable.jpeg);
 background-repeat: no-repeat;
 background-size: cover;*/
}




.case-study{
  font-weight: 500;
  line-height:normal;
  margin-bottom: -16px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 6px;
}


.item-top{
  justify-self: start;

}

.blurry-text{
  color: transparent;
  text-shadow: 0 0 4px rgba(255,255,255,0.7);
}

.yikes{
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  p{
    max-width: 800px;
  }
}

.medable-list{
  font-size: 17px;
    line-height: 28px;
    padding: 0px;
}
