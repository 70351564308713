.project:hover{
 
  background-color: #ffffff;

}



.project{  
  border-radius: 5px;
  transition: all .1s;}
  




.container-projects{
  justify-content: space-between;
  display: flex;
/*  flex-wrap: wrap; */
}

.about{
  width: 65%;
  margin: 0px 0 40px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}

.container-projects-item_left-20{
  display: block;
  margin-left: 32px;
  width: 18%;

}
.container-projects-item_left-10{
  display: block;
  margin-left: 32px;
  width: 12%;

}

.container-projects-item_right-60{
  display: block;
  width: 60%;

}


.container-projects-item_right-80{
  display: block;
  width: 75%;

}



.cases{
  display: flex;
  justify-content: right;  
  cursor: pointer;
  padding: 40px;
  

}

.case-title{
  text-align: right;
  margin-right: 20px;
  line-height: 10px;
  max-width: 260px;
}

.cases:hover{
  background-color: white;
  .black-arrow{
    background: rgba(#000000, $alpha: .04);
    padding: 8px;
    margin: -8px;
  }

}

.container-projects-item_left-30{
  display: inline-block;
  margin-left: 32px;
  margin-bottom: -80px;
  z-index: 1;
  position: relative;
  
}

.bottom-img{
  z-index: 10;
  margin-top: -40px;
  margin-bottom: -8px;
}
.right{
  display: flex
}


@media screen and (max-width: 1080px) {


  .logowhite, .logo{
  height: 18px !important;
}
  .right{
   display: none;
  }
.nav-black{background: white;

}

  .arrow{
    display: none;
  }
  
  .container-projects-item_left-20{
    flex-direction: column;    margin-left: 32px;
    width: inherit;
    z-index: -1;
    }
  .container-projects{
    flex-direction: column;  }
  
  .container-projects-item_right-80{
    flex-direction: column;
    width: 100%;
  }

  .content{
    display: flex !important;
    flex-direction: column;
    ;
  }
  .case-title{
  
    max-width: 100%;
  }

 
  


.container-projects-item_right-60{
  width: 100%;
}

.container-projects-item_left-30{
  flex-direction: column;  margin: 0;
  display: inherit;
}

.bottom-img{
  margin: 0;
}

.about{
  width: fit-content;
  margin: 0 40px ;
  flex-direction: column;}

.cases{
  flex-direction: column;  padding: 40px;
}

.case-title{
  text-align: left;

  
}


}


.case-intro{
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px !important;
  font-weight: 600;

}


.bg-black{
  border: 20px solid;
}


.arrow svg path {
  fill: white; /* Default color */
}

.arrow.blue svg path {
  fill: black; /* Color when project-hero is out of view */
}

.workshop{
  flex-direction: column;

}

.workshop ul{
  flex-direction: column;
    list-style: revert;

    li{
      margin-bottom: 12px;
    }

    
}

.capture{
  background: #dcd7c1;
  width: 100%;
  align-items: center;
  /* border: 1px solid black; */
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
}

.omhu-vid{
  display: block;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    padding: 40px;
    /* align-items: center; */

}

.jobindex-logo {
margin: 0 auto;  width: 260px;
  margin-top: 0px;
}