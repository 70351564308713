

ul{
list-style-type: none;
display: flex; 
padding: 0 20px;
left: 0;
}

.mx-20{
  margin: 0 20px;
}

.myt-10{
  margin-top:10px;
}


.menu-item ul{
  display: block !important; 
  left: 0 !important;
  padding: 0;font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.mini{
  height: 42px;
  font-size: 14px;
}


nav{
  position: fixed;
    display: flex;
    font-family: avenir !important;
    width: 100%;
    justify-content: space-between;
    top: 0;
}


.menu-item{
  padding: 0 12px;
}

.menu-title{
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
}

.work{
  padding: 16px;
  display: flex;
  }

.work:hover{
  background: rgb(255, 255, 255,.6);
  text-decoration: none;
}


.right{
  right: 0 !important;

  ul{
    display: flex !important;
  }
}
.cursor{
  cursor: pointer;
}

.logo{
  height: 24px;
  
}


.nav-scrolled{



  nav-scrolled

  .work:hover {
    background: rgb(255, 255, 255, .2);
    text-decoration: none;
}
}

.nav-white{

  a{
    color: #F3F2EB !important;
  }

  .work:hover {
    background: rgb(255, 255, 255, .2);
    text-decoration: none;
}



.logowhite{
display: block;  height: 24px;
}

.logo{
display: none;
}

.menu-hide{
  display: block;
}

.menu-show{
  display: none;
}

}

.logowhite{
  display: none;;  
  height: 24px;
}

.menu-hide{
  display: none;
}

.top-marker {
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  pointer-events: none; /* Ensure it doesn't interfere with mouse events */
  z-index: -1; /* Keep it behind other elements */
}


.sub-menu{
  ul{
    display: block !important;
  }
}

.nav-responsive{
  width: 100%;
  background: white;
  justify-content: space-evenly;
  padding: 6px 0;
  border-top: 1px solid #F2f2f2;  



  .mini{
    height: 45px; display: none;

  }

  .menu-title{
    font-size: 56px;
    letter-spacing: normal;
   
  }
}



.nav-margin{
  margin: 20px;
}

.bottom-outer{
  display: none ;
}
@media screen and (max-width: 1080px) {

nav{
  position: absolute !important;
}

.nav-responsive{
        width: 100%;
        overflow: hidden;
        scroll-behavior: unset;
        height: 100vh;
        margin: 0 auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        z-index: 1;
        overflow: hidden;
        position: fixed;
        display: flex;

        .burger-menu-hidden{
          width: 60px;
          height: 60px;
          background: white;
          position: fixed;
          top:12px;
          right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          z-index: 20000;
          letter-spacing: 2px;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 14px;
        }


  .work{
    align-items: center;
  }


  a:hover{
    background-color: #f2f2f2;
  }
}

.bottom-outer{
  position: sticky;
  width: 100%;
  display: flex;
  z-index: 100;
  

}}



.burger-menu-see{
  width: 60px;
  height: 60px;
  background: white;
  position: fixed;
  top:12px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  z-index: 1;
  letter-spacing: 2px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  
}
* {
  box-sizing: border-box;
}

.burger-menu-see {
  svg {
    width: 50%;
   
}}


/* Additional styles for better mobile behavior */
@media screen and (max-width: 1080px) {
  .nav-responsive {
    overflow-y: auto; /* Ensure it scrolls vertically if needed */
  }
}