@import "_colors.scss";

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.secondary-button{
  width: 100%;
  background: transparent;    
  position: absolute;
  top: 8px;
  right: 8px;
}
.popup {
  background: #F1F1E8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.30);
  text-align: center;
  max-width: 400px;

 button{
  text-transform: uppercase;
  text-transform: uppercase;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  margin-left: 12px;
  display: flex;
    align-items: center;
    justify-content: center;
 }
}

.popup-inner{
  border: 1px solid $black;
  padding: 24px;
  position: relative;
}


form {
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 1em;
  justify-content: center;
 
}

label {
  margin-bottom: 1em;
}


form{  


  input {
    margin-bottom: 1em;
    padding: 1.2em;
    border-radius: 0px !important;
    border: none;
    width: 260px;
  }

input:focus-visible{
outline-offset: 0cm;
outline: #17A2E1;}

button {
  padding: 0.7em;
  background-color: $black;
  color: white;
  cursor: pointer;
  text-transform: uppercase;

}



button:hover {
  background-color: #3e3b3b;
}

}


.logo-width{
  width: 60px;
}


.max-500{
  max-width: 500px;
}

@media screen and (max-width: 1080px) {

  input {
    font-size: 16px;
  }
}