@import "_colors.scss";

.title{margin-left: 15%; text-align: right;}
/* new font sizes */
.content :nth-child(1) p{
  font-size: 18px;


}
.content :nth-child(2) p{
  font-size: 14px;
}
.content :nth-child(3) p {
font-size: 14px;
}
.xs{
  font-size:16px;
}
.strike {text-decoration: line-through}





.small{
  font-size:1.1vw;
  font-family: 'Avenir','Circular Std', sans-serif;
  font-weight: 400;
}
/* font sizes */


.half-line{
  line-height: 1.5;
}

.manchet{
  font-size: 22px;
}

p {
  font-size: 18px;
  line-height: 1.8;
}

.h2-responsive{
  font-size: 2.6vw;
  font-family: 'Avenir','Circular Std', sans-serif;
    font-weight: 600;
  margin: 4px 0;

}




h3{     
  font-size: 22px;
  font-family: 'Avenir','Circular Std', sans-serif;
  font-weight: 600;
  margin: 4px 0;
  line-height: 1.4;

}


  .text-center{
    text-align: center;
  }

h4 {
  font-size: 18px;
  line-height: 1.5; font-weight: 500;
}

@media screen and (min-width: 320px) {

  
  h4 {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  h4 {
    font-size: 22px;
  }

}



.subcaption{
  color:rgba(0, 0, 0, 0.5);
  font-size: 0.9vw;
  font-weight: 500;
  margin: 0;
}

.project a{
 text-decoration: none !important;
 color: #414141 !important;
 font-weight: 300;
 text-decoration: none;
}



a{
  text-decoration: none;
  color: $black;
}

a:visited{
    text-decoration: none;
    font-weight: 300;
    color:$black;

  }


.project:hover span {
  color: #6599FF;    text-decoration: none;

}


.zero-height{
  line-height: 1 !important;
}


h1 {
  font-size: 42px;
  line-height: 1.2;
  font-family: 'Avenir','Circular Std', sans-serif;  font-weight: 700;
}


.project-sub{
font-size:38px !important;
}
  
@media screen and (min-width: 320px) {
  h4 {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1081px) {
  h1 {
    font-size: 62px;
  }

  .subtitle{
    font-size: 38px;
    }

  
}
@media screen and (max-width: 480px) {

.mega{ font-size: 28px !important;}
}

@media screen and (max-width: 1080px) {
  h3 {
    font-size: 22px;
  }

  .mega{        font-size: 42px !important;
}

  .xs, .small{
    font-size:18px !important;

  }


.about-1{
  width: 100%;
}

  h2{
font-size: 28px
  }

.h2-responsive{
  font-size: 32px;
  margin-top: 28px;
}
  
  .h2-margin{
    margin: 0 40px;
  }

  .subcaption{
    font-size: 18px;
  }

  .content :nth-child(1) {
    font-size: 18px;


  }
  .content :nth-child(2) p{
    font-size: 18px ;
}
.content :nth-child(3) p{
  font-size: 18px;
}
}




/*------ format -----*/


.text-center {
  text-align: center !important;
}

.zero-line-height{
line-height: 100% !important;
}


.arrow2{
  font-size: 30px;
}



.green{
  color: #58bd8b;
  }


  .per{background-color: white;display: inline-grid;}

  a:visited{
    color:$black
  }

  .omhu-h3{
    font-size: 22px !important;
  } 

  .micro{
    font-size: 14px;
  }

  quote{
    font-size: 48px;
  }

  .mega{        font-size: 4.5vw;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 20px;
}
.bold{
  font-weight: 600;
}