$black: #1D1D1B;
$base-color: #EFEFE5;

.bg-red{
    background-color: #d05555;
    }
    
    .bg p{
      color: white;
    }
    
    .bg-green{
    background-color: #528576;
    }
    
    .bg-white{
      background-color: #fff;
    }
    
    .bg-purple{    background: #2b2739;


    }

    .red{
      color:#d05555 !important;
    }