@import "_colors.scss";
@import "_blackbox.scss";
@import "_popup.scss";
@import "_projects.scss";
@import "_medable.scss";

.social{
  height:24px;

}

.App{
overflow-x: none;
}

.logo{
  z-index: 20000;
}

.pill-container{
  margin-top: 12px;
:first-child{
  margin-left: 0px;
}

:last-child{
  margin-right: 0px;
}
  .pill{
    display:inline-block;
  }
}

.sticky{
  position: sticky; 
    position: -webkit-sticky;
    position: sticky;
    top: 140px;
    display: flex;
}
.item-45{
  width: 60%;
  margin: 20px 0;
  display: block;

  ul{
    display: block;
    list-style-type: disc;
    font-size: 18px;
    font-family: "Avenir", "Circular Std", sans-serif;
    font-weight: 400;
  li{margin-bottom: 16px; line-height: 1.8;
  }
  }
}
.intro{
  margin-bottom: 0px;

}

.services{
  display: flex;
}
.item-25{
  width: calc(25% - 20px);
  display: inline-block;
  padding-right: 20px;

  ul{
    display: block;
    padding:0;

  }
}


.item-50{
  width: 50%;
  display: inline-block;
  margin: 0;
}
section {
  width: 100%;
  /*min-height: 100vh;*/
  display : flex;
}

.middle {
  /* needs to be vertically and horizontally aligned */
margin : auto;
width: 75vw;
padding-top: 120px;
}


body{

  font-family: 'Avenir Next','Circular Std', sans-serif;
  color: $black;
  margin: 0 auto;  background-color: #F6F6EF;
  opacity: 1;
/*  background-image: radial-gradient(#c6c6bb 0.7px, #F6F6EF 0.7px);
  background-size: 10px 10px; */

}

.item_small{
  width: 31%;
  padding: 10px;
}

.slim{
  width: 70%;
  display: flex;
  justify-content: space-between;}

.button{
  border: 1px solid #04BF9D !important;
  padding: 10px 20px !important;
  border-radius: 100px !important;
  color: #04BF9D !important;
  min-width: 139px;
  text-decoration: none;
  z-index: 200000;
}

.badge{
  padding: 10px 14px !important;
  border-radius: 100px !important;
  color: rgba(255,255,255,1) !important;
  min-width: 60px;
  text-decoration: none;
  background-color: rgba(255,255,255,0.080);
  text-transform: uppercase;
  font-size: 10px !important;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
}

.badges > * {
  margin: 0 0 12px 0px;
  display: inline-block;
}

.badges > *:not(:last-child) {
  margin-right: 12px;
  display: inline-block;
}

.item{
  flex-basis:50%;    
  padding: 20px;
  margin-bottom: 20px;
}


.item-1{
  flex-basis:30%;
  flex-shrink: inherit;    padding: 20px;
  align-self: baseline;
  margin-left: -20px;
  margin-right: -20px;
}

.item-2{
  flex-basis:40%;
  margin-top: 160px !important;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;

}

.item-3{
  flex-basis:60%;
  margin-bottom: 60px;
  margin-top: 120px !important;
  padding: 20px;
  margin-left: 20%;
  margin-right: -20px;

}


hr{
  margin: 0;
}
  
.container{
  margin-left: 15%;
  margin-right: 15%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.center-parent{
  justify-content: center !important;
}

.img-fluid{
  width: 100%;
}

.img-inner{  
  flex-basis: 60%; 
}

.text-inner{
  flex-basis: 40%;
  align-self: flex-end;
}

.projects{
  padding-top:40px;
}

.bm-burger-button {
  position: fixed;
  right: 5% !important;
  top: 103px;
}

.home{
  height: 100%;
}

.page{
  margin-bottom: 100px;
}

button{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  color: #414141;
  fill:#414141;
  z-index: 20000;
}


.arrow{
  top: 40px;
  left: 1%;
  position: fixed;  
}



.video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.krea-1{
  display: flex;
    flex-direction: row-reverse;
  
}

.krea-1-1{display: flex;
  align-content:center;
  flex-direction: column;
  justify-content:center;
  align-items: flex-start;  
  margin: 0 auto;
  width: 50%;}

  .krea-1-1 p{
    text-align: left;
text-justify: newspaper; 
font-size: 18px; }


/*nav*/
@media screen and (max-width: 1080px) {

.middle{
  margin: 40px;
  width: 100%;
}
.item-45{
  width: 100%;
}

.item{
  width: 100%;
  flex-basis: 100%;
}

.services{
  display: inline-grid;
}

.item-25{
  width: 100%;
  display: inline-block;
  padding-right: 20px;


}

.sticky{
  margin-right: 40px;
}

.item-50{
  width: 100%;
}

.blob{
  order: 1;
}

.services{
  order: 2;
}
.contact{
  order: 3;
}

section {height: 100% !important;
}






}

.about-1{
  flex-wrap: wrap;
    margin: 0 0 40px;
    width: 100%;
    display: block;
}



.paternity{
  background-color: rgba(#000000, $alpha: .08);
  padding: 4px 8px;
  margin-left: -8px;
  border-radius: 4px;
  margin-bottom: 8px;
  width: 100%;

}

.hidden{
  display: none;
}

.column{
  flex-direction: column;
}

.border-radius{
  border-radius: 12px;
  overflow: hidden;
}

.block{
  display: block !important;
}

.pill{
  background: rgba(#000000, $alpha: .05);
  padding: 2px 4px ;
  border-radius: 2px;
  letter-spacing: 1px;
margin-right: 8px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.pill-white{
 
  background: rgba(#ffffff, $alpha: .7);
padding: 2px 4px ;
border-radius: 2px;
letter-spacing: 1px;
margin-right: 8px;
font-size: 10px;
font-weight: 500;
text-transform: uppercase;
}

.black-arrow{
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 15%;
  font-weight: 600;

  svg{
    margin-bottom: -2px;
    margin-left: 10px;
  }
}



.content{
  display: grid;
  grid-template-columns: [first] 48%  [line3] 48% [end];
  justify-content: space-between;    
 line-height: 1.6;
}


.content{

  ul{
    display: block;
    list-style-type:disc
  }
ul li{
  
  font-size: 16px !important;
  margin-bottom: 16px;
}

}

.button-pill{
  margin-left: 4px !important;
  position: absolute;
  margin-top: 12px !important;
  margin-left: 12px !important;
  background-color: #f9a17b8e;
  font-weight: 600;
  padding: 4px;
  letter-spacing: 1.5px;
  color: #000000b6;
  
}


.roles {
  
  li{
  align-items: center;
  display: flex;
  width: fit-content;
}}