@import "_colors.scss";

.blackbox {
  background-color: $black;
  width: 360px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  color: $base-color;
  padding: 16px 12px;
  font-family: "Avenir Next", sans-serif;
  line-height: 24px;
  z-index: 4;
}

.red-dot {
  border-radius: 1000px;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: red;
  margin-right: 6px;

  transform: translateY(-5%) !important;
  animation: fadein 1s ease-in-out alternate infinite;
}

@keyframes fadein {
  0% {
    opacity: 0.6;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(65,227,12,1);
-moz-box-shadow: 0px 0px 0px 0px rgba(65,227,12,1);
box-shadow: 0px 0px 0px 0px rgba(65,227,12,1);
  }
  100% {
    opacity: 1;
    -webkit-box-shadow: 0px 0px 1px 1px rgb(227, 12, 12);
-moz-box-shadow: 0px 0px 1px 1px rgb(227, 12, 12);
box-shadow: 0px 0px 1px 1px rgb(227, 12, 12);
  }
}

.white-box {
  padding: 40px;
  background-color: rgba(255,255,255,1) ;
  z-index: 0;
}

@media screen and (max-width: 1080px) {
  .blackbox {
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-right: 20px;
    }

}



@keyframes scroll {
  0% {
    transform: translateX(100%); /* Start from the right */
  }
  100% {
    transform: translateX(-100%); /* End at the left */
  }
}


.temp-link{
  
/*  background: rgba(23, 162, 225, 0.7); font-weight: 700;   text-decoration-style: wavy;
*/
  padding: 0px 4px;
  border-radius: 2px;
  text-decoration-line: underline;
 background: none;
   color: rgba(255,255,255,.9) !important;font-weight: 600;

  
}

.temp-link:hover{
  color:$black !important;
  background: rgba(255,255,255,1); padding: 0px 4px !important;
  border-radius: 2px !important;
  text-decoration: none !important;
  font-weight: 600 !important;

}
